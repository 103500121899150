export class Rectangle {
  constructor(_x, _y) {
    this.x = _x;
    this.y = _y;
  }
  set addWidth(width) {
    this.x += width;
  }
  addWidthFunction(width) {
    this.x += width;
  }
}
