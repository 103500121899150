<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="Search"
          outlined
          v-model="searchValue"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row
      v-for="(mealType, i) in searchValue !== '' ? searchedMeals : meals"
      :key="i"
    >
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <v-card-title>{{ i }}</v-card-title>
      </v-col>
      <v-col cols="6" lg="3" v-for="(meal, i) of mealType" :key="i">
        <v-card>
          <v-card-title>
            {{ meal.name }}
            <v-spacer></v-spacer>
            <v-btn fab small>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-spacer class="pb-6 mb-16"></v-spacer>
  </v-container>
</template>
<script>
import * as Utils from "../classes/classes";

export default {
  name: "KitchenMealBuilder",
  data: function () {
    return {
      searchValue: "",
      value: new Utils.Rectangle(2, 3),
      meals: {
        Breakfast: [
          { name: "Bacon and Eggs" },
          { name: "Timesaver" },
          { name: "Pancakes" },
          { name: "Waffles" },
        ],
        Lunch: [
          { name: "Coconut Curry" },
          { name: "Borscht" },
          { name: "Cream of Broccoli" },
        ],
        Supper: [
          { name: "Pineapple Chicken And Rice" },
          { name: "Pork Tenderloin" },
          { name: "Beef Roast" },
          { name: "Roast Chicken" },
        ],
        Snack: [{ name: "Chicken Wings" }, { name: "Cheese" }],
      },
    };
  },
  computed: {
    searchedMeals() {
      let output = {};
      for (let mealType in this.meals) {
        output[mealType] = this.meals[mealType].filter((v) =>
          v.name.includes(this.searchValue)
        );
      }
      return output;
    },
  },
  methods: {
    addWidth() {
      this.value.addWidthFunction(1);
    },
  },
};
</script>
